import { Text } from '@qga/roo-ui/components';
import React from 'react';

const PerPerson = () => {
  return (
    <Text mt="1" fontSize={['2xs', null, 'sm']} alignSelf="center">
      /person*
    </Text>
  );
};

export default PerPerson;
