/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box } from '@qga/roo-ui/components';
import { height, style, width } from 'styled-system';
import placeholder from './assets/placeholder.svg';

const StyledImage = styled.img`
  ${height}
  ${width}
  ${style({ prop: 'fit', cssProperty: 'objectFit' })};
`;

const Wrapper = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Placeholder = styled(StyledImage)`
  max-width: 50%;
`;

const Image = ({ alt, src, ssr, ...props }) => {
  const [error, setError] = useState(!src);
  const [clientSide, setClientSide] = useState(ssr);

  useEffect(() => {
    setClientSide(true);
  }, []);

  if (ssr) {
    return <StyledImage {...props} alt={alt} src={src} />;
  }

  if (error || !clientSide) {
    return (
      <Wrapper {...props} p="4" backgroundColor="greys.porcelain">
        <Placeholder width="75px" alt={alt} src={placeholder} />
      </Wrapper>
    );
  }

  return (
    <StyledImage
      {...props}
      alt={alt}
      src={src}
      onError={() => setError(true)}
    />
  );
};

Image.defaultProps = {
  src: undefined,
  alt: 'Image Coming Soon',
  ssr: false,
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  ssr: PropTypes.bool,
};

export default Image;
