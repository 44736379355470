import React, { ReactNode } from 'react';
import { Flex, Text } from '@qga/roo-ui/components';
import { Dollar, PerPerson, Pts } from './components';
import styled from '@emotion/styled';

export type PriceTextProps = {
  children: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  dataTestId?: string;
  compact?: boolean;
};

const StyledText = styled(Text)`
  &:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

const PriceText = ({
  children,
  prefix,
  suffix,
  dataTestId,
  compact,
}: PriceTextProps) => {
  return (
    <Flex data-testid={dataTestId} alignItems="center">
      {prefix}
      <StyledText
        fontSize={compact ? 'base' : ['xl', null, '3xl']}
        lineHeight={compact ? '1.5' : '40px'}
        fontWeight="bold"
      >
        {children}
      </StyledText>
      {suffix}
    </Flex>
  );
};

PriceText.Dollar = Dollar;
PriceText.PerPerson = PerPerson;
PriceText.Pts = Pts;

export default PriceText;
