import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text } from '@qga/roo-ui/components';

const Summary = ({ text, prefix, dataTestId = 'SUMMARY', ...rest }) => (
  <Flex alignItems="center" data-testid={dataTestId} {...rest}>
    {prefix && (
      <Flex
        alignItems="center"
        mr="2"
        size={18}
        data-testid={`${dataTestId}-PREFIX`}
      >
        {prefix}
      </Flex>
    )}
    <Text fontSize={['2xs', null, 'sm']} textStyle="summary">
      {text}
    </Text>
  </Flex>
);

Summary.propTypes = {
  text: PropTypes.string.isRequired,
  prefix: PropTypes.node,
  dataTestId: PropTypes.string,
  textProps: PropTypes.object,
};

export default Summary;
