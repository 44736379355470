import { Text } from '@qga/roo-ui/components';
import React from 'react';

export type PtsProps = {
  compact?: boolean;
};
const Pts = ({ compact }: PtsProps) => {
  return (
    <Text
      mt={compact ? '0' : '1'}
      fontSize={compact ? '12px' : ['2xs', null, 'sm']}
      alignSelf="center"
    >
      PTS^
    </Text>
  );
};

export default Pts;
