import PropTypes from 'prop-types';

export default {
  originCode: PropTypes.string,
  departureDate: PropTypes.string,
  returnDate: PropTypes.string,
  adults: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  infants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
