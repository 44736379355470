import PropTypes from 'prop-types';
import React from 'react';
import { Box, Text } from '@qga/roo-ui/components';
import Travellers from '@/libs/propTypes/Travellers';
import getTravellersDescription from '../../utils/getTravellersDescription';
import PriceText from '@/v2/components/ui/PriceText';
import { formatNumber } from 'accounting';

const TotalPoints = ({
  points,
  travellers,
  displayTravellerTypes,
  textAlign,
  showSummary,
}) => (
  <Box data-testid="TOTAL_POINTS" color="greys.charcoal" textAlign={textAlign}>
    <PriceText dataTestId="POINTS" suffix={<PriceText.Pts />}>
      {formatNumber(points)}
    </PriceText>
    {travellers && (
      <Text fontSize={['2xs', null, 'sm']} fontWeight="bold">
        {!showSummary && 'Total '}
        {`for ${getTravellersDescription(travellers, displayTravellerTypes)}`}
      </Text>
    )}
  </Box>
);

TotalPoints.defaultProps = {
  previousPoints: undefined,
  travellers: undefined,
  displayTravellerTypes: false,
  textAlign: 'left',
};

TotalPoints.propTypes = {
  points: PropTypes.number.isRequired,
  previousPoints: PropTypes.number,
  travellers: PropTypes.shape(Travellers),
  displayTravellerTypes: PropTypes.bool,
  textAlign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default TotalPoints;
