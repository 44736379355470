import pluralize from 'pluralize';
import { getTotalTravellers } from '@/libs/utils';
import getOccupantsSentence from '../getOccupantsSentence';

// TODO: traveller type test
const getTravellersDescription = (
  travellers,
  displayTravellerTypes = false,
  travellerType = 'traveller',
) => {
  if (displayTravellerTypes) {
    return getOccupantsSentence(travellers);
  }

  return pluralize(travellerType, getTotalTravellers(travellers), true);
};

export default getTravellersDescription;
