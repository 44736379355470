import { Text } from '@qga/roo-ui/components';
import React from 'react';

export type DollarProps = {
  compact?: boolean;
};

const Dollar = ({ compact }: DollarProps) => {
  return (
    <Text
      fontSize={compact ? '2xs' : ['base', null, 'md']}
      lineHeight={compact ? '1.5' : ['2.25rem', null, '1.5']}
      fontWeight="bold"
      alignSelf="flex-start"
    >
      $
    </Text>
  );
};

export default Dollar;
