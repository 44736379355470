/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const withProps = (input) => (Component) =>
  React.forwardRef((props, ref) => (
    <Component
      ref={ref}
      {...props}
      {...(typeof input === 'function' ? input(props) : input)}
    />
  ));

export default withProps;
